<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card" id="maincard">
				<Toast/>

                <h4>Paraméterek</h4>
                <h5>Lejelenthetőség időszaka</h5>

                <span class="p-field p-float-label">
                    <Calendar id="datefrom" name="x-param-datum" v-model="param.datefrom" dateFormat="yy.mm.dd" :showIcon="true" :showButtonBar="true" :disabled="loading" autocomplete="off"></Calendar>
                    <label for="datefrom">Dátumtól</label>
                </span>
                <span class="p-field p-float-label">
                    <Calendar id="dateto" name="x-param-datumto" v-model="param.dateto" dateFormat="yy.mm.dd" :showIcon="true" :showButtonBar="true" :disabled="loading" autocomplete="off"></Calendar>
                    <label for="dateto">Dátumig</label>
                </span>
                <span class="p-field">
						<Button label="Mentés" :icon="(loading) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" :disabled="!edited"
                           @click="save"/> <!--:disabled="v$.$error"/-->
                </span>
                <span class="p-field">
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" :disabled="!edited" 
                            @click="cancel"/>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import ParamService from '@/service/backend/param.service'
export default {
    data() {
        
        const param = function() {
            return {datefrom: null, dateto: null}
        }

        return {
            loading: true,

            param: param(),
            default_param: param()
        }
    },
    methods: {
        loadParam: async function() {
            try{
                const result = await this.paramService.getParam() // 1
                this.param.datefrom = new Date(Date.parse(result[0].datefrom))
                this.param.dateto = new Date(Date.parse(result[0].dateto))
                this.setDefault(Date.parse(result[0].datefrom), Date.parse(result[0].dateto))
            }catch(e){
                console.log(e)
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }finally{
                this.loading = false
            }
            //this.doctor = JSON.parse(JSON.stringify(this.doctors.find( d => d.id === id)));
        },
        cancel() {
            this.param.datefrom = new Date(this.default_param.datefrom)
            this.param.dateto = new Date(this.default_param.dateto)
        },
        save: async function() {
            this.loading = true
            try{
                await this.paramService.updateParam(this.param) // 1
                this.setDefault(this.param.datefrom, this.param.dateto)
                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Mentve!', life: 3000})
            }catch(e){
                console.log(e)
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }finally{
                this.loading = false
            }
        },
        setDefault(datefrom, dateto) {
            this.default_param.datefrom = new Date(datefrom)
            this.default_param.dateto = new Date(dateto)
        }
    },
    paramService: null,
	created() {
		this.paramService = new ParamService()
        this.loadParam()
    },
	mounted() {
    },
    computed: {
        edited(){
            return (JSON.stringify(this.param.datefrom) !== JSON.stringify(this.default_param.datefrom)
                    ||
                    JSON.stringify(this.param.dateto) !== JSON.stringify(this.default_param.dateto)
                    )
        }
    }
}
</script>
<style scoped>
#maincard {
    padding-bottom: 40px;
}
.p-field {
    display: inline-flex;
    margin-right: 3rem;
}
</style>