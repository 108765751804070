import ApiService from './api.service'

export default class ParamService {

	async getParam() {
        const response = await ApiService.get('api/params')
        return response.data.data
    }

	async updateParam(param) {
        const response = await ApiService.put('api/params/1', param)
        return response.data.id
    }

}
